// http://ionicframework.com/docs/theming/
@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700');

@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

// EXTRA GLOBAL STYLES
// Add custom Ionic Colors
@import 'theme/custom-ion-colors.scss';
// Add base app styles
@import 'theme/app-defaults.scss';
// Add base shell styles
@import 'theme/shell-defaults.scss';

.toastSuccess {
  transform: translateY(-56px) !important;
  color: white;
  --background: var(--ion-color-aqua-dark-shade-1);
  --button-color: white;
}

.toastError {
  transform: translateY(-56px) !important;
  color: white;
  --background: #f4344f;
  --button-color: white;
}

.toastNeutral {
  transform: translateY(-56px) !important;
  color: black;
  --background: #e8ebf2;
  --button-color: black;
}

.toastSuccessNoTab {
  color: white;
  --background: var(--ion-color-aqua-dark-shade-1);
  --button-color: white;
}

.toastErrorNoTab {
  color: white;
  --background: #f4344f;
  --button-color: white;
}

.not-map-ion-content {
  // overwrite inline styles
  --offset-bottom: auto !important;
  --overflow: hidden;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
