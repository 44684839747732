ion-app {
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  --ion-background-color: #ffffff;
}

ion-title {
  // Adding !important to force precedence in SSR
  font-weight: 400 !important;
  letter-spacing: 0.4px !important;
}

ion-toolbar {
  --background: var(--ion-color-aqua-dark-shade-1);
  --color: white;
}

ion-back-button {
  color: white;
}

// Override Ionic styles
.input-clear-icon {
  // Change these properties so the Ionic close icon aligns with show/hide password
  &.sc-ion-input-ios,
  &.sc-ion-input-md {
    width: calc(1.2rem);
    margin-inline-start: 0.5rem;
  }
}

.list-grid {
  margin-top: var(--app-broad-margin);
  margin-bottom: var(--app-broad-margin);
}

.list-whatever {
  // padding-top: var(--app-broad-margin);
  // padding-bottom: var(--app-broad-margin);
  height: 100px;
  border-bottom: solid 1px #c8c7cc;
}

.list-label {
  font-size: var(--app-font-big) !important;
  font-weight: var(--app-font-fat) !important;
  width: fit-content;
}

.list-label-whatever {
  font-size: var(--app-font-big) !important;
  font-weight: var(--app-font-fat) !important;
  height: 100%;
}

.list-sub-label {
  font-size: var(--app-font-small) !important;
  font-weight: var(--app-font-skinny) !important;
}

.list-icon {
  padding-left: var(--app-fair-margin);
}

.my-custom-modal-class .modal-wrapper {
  height: 30%;
  top: 70%;
  position: absolute;
  display: block;
  border-radius: 15px 15px 0px 0px;
}
